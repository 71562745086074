<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>市场领域</el-breadcrumb-item>
      <el-breadcrumb-item>领域列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 视图区 -->
    <el-card>

      <el-button type="primary"
                 class="addbtn"
                 @click="goAddPage">添加市场领域</el-button>

      <!-- 视图列表区 -->
      <el-table :data="showList"
                style="width: 100%;margin-bottom: 20px;"
                row-key="id"
                stripe
                default-expand-all
                :tree-props="{children: 'children'}">
        <el-table-column type="index"
                         label="#"></el-table-column>
        <el-table-column prop="name"
                         label="市场领域"></el-table-column>
        <el-table-column prop="status"
                         label="是否显示">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status"
                       :active-value="1"
                       :inactive-value="0"
                       @change="stateChange(scope.row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="update_time"
                         label="更新日期"></el-table-column>
        <el-table-column label="操作"
                         width="200">
          <template slot-scope="scope">
            <el-button type="primary"
                       icon="el-icon-edit"
                       size="mini"
                       @click="goEditPage(scope.row.id)">编辑</el-button>
            <el-button type="warning"
                       icon="el-icon-delete"
                       size="mini"
                       v-if="scope.row.status === 0"
                       @click="deleteById(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showList: []
    }
  },

  created () {
    this.getList()
  },

  methods: {
    // 获取全部数据列表
    async getList () {
      const { data: res } = await this.$http.get('markets')
      if (res.code !== 200) {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      this.showList = res.data
    },

    // 改变状态
    async stateChange (userinfo) {
      const { data: res } = await this.$http.put(`markets/${userinfo.id}?status=${userinfo.status}`)
      if (res.code !== 200) {
        userinfo.status = !userinfo.status
        return this.$message.error('更新状态失败！')
      }
      this.$message.success('更新状态成功！')
      // 刷新列表
      this.getList()
    },

    // 删除该ID信息
    async deleteById (id) {
      const confirmResult = await this.$confirm('您确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }
      ).catch(err => err)

      // 如果用户取消了删除
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除！')
      }
      const { data: res } = await this.$http.delete('markets/' + id, {
        headers: { Authorization: localStorage.getItem('token') }
      })
      if (res.code !== 200) {
        return this.$message.error('删除失败，请重试...')
      }
      this.$message.success('删除成功！')
      this.getList()
    },

    goAddPage () {
      this.$router.push('/market/add')
    },

    goEditPage (rowid) {
      this.$router.push(
        { path: '/market/edit/' + rowid }
      )
    }
  }
}
</script>

<style scoped>
</style>
