<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>公司信息</el-breadcrumb-item>
      <el-breadcrumb-item>{{ pageTitle }}</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 视图区 -->
    <el-form :model="dateForm"
             :rules="dateFormRules"
             ref="dateFormRef"
             label-position="top">
      <el-tabs type="border-card">
        <el-tab-pane label="基本信息">
          <el-row>
            <el-col :span="16">
              <el-form-item label="市场领域："
                            prop="name">
                <el-input v-model="dateForm.name"></el-input>
              </el-form-item>

              <el-form-item label="简述："
                            prop="summary">
                <el-input type="textarea"
                          :rows="6"
                          resize="none"
                          v-model="dateForm.summary"></el-input>
              </el-form-item>

              <!-- 分类选择 -->
              <!-- <el-form-item label="上级分类：">
            <el-cascader v-model="selectedKeys"
                         :options="parentCateDate"
                         :props="{ expandTrigger: 'hover', value: 'id', label: 'catname', children: 'children', checkStrictly: true }"
                         :placeholder="placeholder"
                         clearable
                         @change="handleCheckChange">
            </el-cascader>
          </el-form-item> -->

              <el-form-item label="图片上传："
                            prop="logo">
                <el-upload :data="imgtype"
                           :action="uploadURL"
                           :headers="headerObj"
                           :limit="1"
                           :on-preview="handlePreview"
                           :before-upload="beforeUpload"
                           :on-success="handleSuccess"
                           :file-list="fileList"
                           list-type="picture">
                  <el-button size="small"
                             type="primary">点击上传</el-button>
                  <div slot="tip"
                       class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
                </el-upload>
                <el-dialog :visible.sync="imgDialogVisible"
                           width="50%"
                           append-to-body>
                  <img width="100%"
                       :src="dialogImageUrl"
                       alt="">
                </el-dialog>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>

        <el-tab-pane label="内容详情">
          <el-form-item label="市场领域详情">
            <TEditor v-model="dateForm.content"
                     :baseUrl="baseUrl" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary"
                       @click="saveForm">提交</el-button>
            <el-button @click="goBack">取消</el-button>
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
    </el-form>

  </div>
</template>

<script>
export default {
  data () {
    return {
      addData: '添加领域',
      updateData: '修改领域信息',
      pageTitle: '',

      baseUrl: '/logo?type=markets',

      dateForm: {
        name: '',
        summary: '',
        logo: '',
        content: ''
      },
      // 添加验证规则
      dateFormRules: {

      },
      // 父级分类列表信息
      parentCateDate: [],

      // 选中的父级数组
      selectedKeys: [],

      // 级联选择器占位符
      placeholder: '请选择所属分类，默认为顶级分类',

      // 图片上传地址
      uploadURL: this.globalUrlapi + 'logo',
      headerObj: {
        Authorization: window.sessionStorage.getItem('token')
      },
      imgtype: {
        type: 'markets'
      },
      fileList: [],
      imgDialogVisible: false,
      dialogImageUrl: ''
    }
  },

  methods: {
    // 获取数据
    async getDate (id) {
      const { data: res } = await this.$http.get('markets/' + id)
      if (res.code !== 200) {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      this.dateForm = res.data
      this.fileList.push({ name: res.data.logo, url: this.globalUrlapi + res.data.logo })
      this.selectedKeys = res.data.pid
      this.getParentCateList()
    },

    // 获取父级分类数据
    async getParentCateList () {
      const { data: res } = await this.$http.get('categorys')
      if (res.code !== 200) {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      this.parentCateDate = res.data
    },

    // 添加表单分类选择器触发函数
    handleCheckChange () {
      // 如果 this.selectedKeys 数组中的 length 大于0，则选中父级分类
      // 反之，就说明没有选中任何父级分类
      if (this.selectedKeys.length > 0) {
        this.dateForm.pid = this.selectedKeys[this.selectedKeys.length - 1]
        this.dateForm.level = this.selectedKeys.length
      } else {
        this.dateForm.pid = 0
        this.dateForm.level = 0
      }
    },

    // 查看图片
    handlePreview (fileList) {
      this.dialogImageUrl = fileList.url
      this.imgDialogVisible = true
    },

    // 图片上传前校验
    beforeUpload (file) {
      const isIMAGE = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt1M = file.size / 1024 / 500 < 1
      if (!isIMAGE) {
        this.$message.error('上传文件只能是 jpg 或 png 图片格式!')
      }
      if (!isLt1M) {
        this.$message.error('上传文件大小不能超过 500kb!')
      }
      return isIMAGE && isLt1M
    },

    // 图片上传成功回调
    handleSuccess (response) {
      this.dateForm.logo = response.data
    },

    // 新增方法
    async addForm () {
      const { data: res } = await this.$http.post('markets', this.dateForm)
      if (res.code !== 200) {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      this.$message.success('添加成功！')
      // 跳转
      this.$router.push('/market')
    },

    // 修改方法
    async updateForm () {
      const { data: res } = await this.$http.put('markets/' + this.dateForm.id, {
        name: this.dateForm.name,
        summary: this.dateForm.summary,
        pid: this.dateForm.pid,
        logo: this.dateForm.logo,
        content: this.dateForm.content
      })
      if (res.code !== 200) {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      this.$message.success('修改成功')
      // 跳转
      this.$router.push('/market')
    },

    init () {
      // 判断是否有参数
      if (this.$route.params && this.$route.params.id) {
        this.getDate(this.$route.params.id)
        this.pageTitle = this.updateData
      } else {
        this.pageTitle = this.addData
      }
    },

    saveForm () {
      this.$refs.dateFormRef.validate(valid => {
        if (!valid) return
        // 判断id是否存在  存在则为修改
        if (this.dateForm.id) {
          this.updateForm()
        } else {
          this.addForm()
        }
      })
    },

    // 取消路由跳转
    goBack () {
      this.$router.push('/market')
    }
  },

  created () {
    this.init()
  }
}
</script>

<style>
</style>
